import alert from './alert';

const QUESTIONS = 5;
let answers = [];

export default function fInitShopAssistant() {
    document.addEventListener('click', function (event) {
        const answerTrigger = event.target.closest('.js-shop-assistant-answer');
        const resetTrigger = event.target.closest('.js-shop-assistant-reset');
        const submitTrigger = event.target.closest('.js-shop-assistant-submit');

        if ( answerTrigger ) fHandleAnswer(answerTrigger);
        if ( resetTrigger ) fResetShopAssistant();
        if ( submitTrigger ) fSubmitAnswers();
    });
};

function fResetShopAssistant() {
    fShowNextQuestion(0);
    answers = [];
    document.querySelectorAll('.js-shop-assistant-answer.selected').forEach(function (el) {
        el.classList.remove('selected');
    })
}

function fHandleAnswer(trigger) {
    const questionIndex = parseInt(trigger.parentNode.dataset.question_number, 10);
    document.querySelectorAll('.modal__assistant__answergroup[data-question_number="'+questionIndex+'"] .js-shop-assistant-answer.selected').forEach(function (el) {
        el.classList.remove('selected');

    });

    trigger.classList.add('selected');

    answers[questionIndex] = trigger.dataset.answer_number;

    if ( questionIndex === QUESTIONS-1 ) {
        fShowGiftsButton();
    } else {
        setTimeout( function () {
            fShowNextQuestion(questionIndex+1);
        }, 200);
    }
}

function fShowNextQuestion(index) {

    const all = document.querySelectorAll('.modal__assistant__answergroup');

    for (let i = 0; i < all.length; ++i) {
        all[i].classList.add('hidden');
    }

    document.querySelector('.modal__assistant__answergroup[data-question_number="' + index + '"]')
        .classList
        .remove('hidden');
}

function fShowGiftsButton() {
    document.querySelector('#sa_show_gifts_button').classList.remove('hidden');
}

function fSubmitAnswers() {
    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
            try {
                const response = JSON.parse(xhr.response);
                window.location.href = response.href;
            } catch (e) {
                window.location.href = '/index.php?route=common/home';
            }
        } else {
            alert('Something went wrong. Please try again.');
        }
    };

    xhr.open('POST', document.querySelector('#shopAssistantModal').dataset.url);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    let result = [];

    for (let i = 0; i < answers.length; i++) {
        result.push(`question${i}=${answers[i]}`);
    }

    xhr.send(result.join('&'));
}