export default function fInitNews() {
    const oNewsConfig = {
        tags: document.querySelectorAll('.js-news-tag'),
        defaultTag: document.querySelector('.js-news-default'),
        nPageId: document.querySelector('.js-news-tags') ? parseInt(document.querySelector('.js-news-tags').getAttribute('data-news-pageid'), 10) : 0
    };

    // Filter news article(s) by tag
    function fFilterNewsArticles(oTrigger) {
        const sTag = oTrigger.getAttribute('data-news-tag');
        const oRequest = new XMLHttpRequest();

        if (!sTag) return;

        // Switch active classes
        for (let i = 0; i < oNewsConfig.tags.length; i += 1) {
            oNewsConfig.tags[i].classList.remove('is-active');
        }
        oTrigger.classList.add('is-active');

        // Get news article(s)
        oRequest.open('GET', `/Umbraco/Surface/NewsOverview/GetAvailableNewsArticles?tag=${oTrigger.getAttribute('data-news-tag')}&pageId=${oNewsConfig.nPageId}`, true);
        oRequest.onreadystatechange = () => {
            if (oRequest.readyState === 4) {
                if (oRequest.status === 200) {
                    const newsResultsContainer = document.querySelector('.js-news-results');
                    if (newsResultsContainer) newsResultsContainer.innerHTML = oRequest.responseText;
                }
            }
        };
        oRequest.send();
    }

    // Get the default news article(s)
    if (oNewsConfig.defaultTag) fFilterNewsArticles(oNewsConfig.defaultTag);

    // Bind click event for tags
    document.addEventListener('click', function(event) {
        if (event.target.matches('.js-news-tag') || event.target.closest('.js-news-tag')) {
            fFilterNewsArticles(event.target);
        }
    });
}
