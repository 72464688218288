export default function fInitCustomSelects() {
    const aCustomSelects = document.querySelectorAll('.js-select');

    const destroyCustomSelect = (select) => {
        const oCustomSelectList = select.querySelector('.select__list');
        const oCustomSelectTrigger = select.querySelector('.select__trigger');

        select.removeChild(oCustomSelectList);
        select.removeChild(oCustomSelectTrigger);
    };

    const clickHandler = (select, listEl, oCustomSelectTrigger) => {
        const setValue = listEl.getAttribute('rel');
        select.value = setValue;

        oCustomSelectTrigger.textContent = listEl.textContent;

        if ('createEvent' in document) {
            const evt = document.createEvent('HTMLEvents');
            evt.initEvent('change', false, true);
            select.dispatchEvent(evt);
        } else {
            select.fireEvent('onchange');
        }

        // const invalid = validate.hasError(select);
        // if (!invalid) {
        //   validate.removeError(select);
        // }
    };

    const createCustomSelect = (select) => {
        const selectContainer = select;
        const oSelect = selectContainer.querySelector('select');
        const aSelectOptions = oSelect.options;
        const oCustomSelectList = document.createElement('ul');
        const oCustomSelectTrigger = document.createElement('div');

        oSelect.classList.add('sr-only');
        oCustomSelectList.classList.add('select__list');
        oCustomSelectTrigger.classList.add('select__trigger');

        if (oSelect.value !== '' || oSelect.querySelector('[selected]')) {
            oCustomSelectTrigger.textContent =
                oSelect.value !== ''
                    ? oSelect.querySelector(`option[value="${oSelect.value}"]`)
                        .textContent
                    : oSelect.querySelector('[selected]').textContent;
        } else {
            oCustomSelectTrigger.textContent = aSelectOptions[0].textContent;
        }

        document.addEventListener('click', (event) => {
            const selectTrigger = event.target === oCustomSelectTrigger;

            if ( selectTrigger ) {
                if ( oCustomSelectList.classList.contains('is-open') ) {
                    oCustomSelectList.classList.remove('is-open');
                    selectContainer.classList.remove('is-open');
                } else {
                    oCustomSelectList.classList.add('is-open');
                    selectContainer.classList.add('is-open');
                }
                return false;
            }

            oCustomSelectList.classList.remove('is-open');
            selectContainer.classList.remove('is-open');
        });

        for (let i = 1; i < aSelectOptions.length; i += 1) {
            // i = 1 skips placeholder
            const listEl = document.createElement('li');
            listEl.textContent = aSelectOptions[i].textContent;
            listEl.setAttribute('rel', aSelectOptions[i].value);
            listEl.classList.add('select__list__item');


            if (aSelectOptions[i].disabled) {
                listEl.classList.add('select__list__item--disabled');
            }

            oCustomSelectList.appendChild(listEl);

            listEl.addEventListener('click', () => {
                clickHandler(oSelect, listEl, oCustomSelectTrigger);
            });
        }
        selectContainer.appendChild(oCustomSelectTrigger);
        selectContainer.appendChild(oCustomSelectList);
    };

    for (let i = 0; i < aCustomSelects.length; i += 1) {
        const select = aCustomSelects[i];

        if (select.querySelector('.select__trigger')) {
            destroyCustomSelect(select);
        }

        createCustomSelect(select);
    }
}