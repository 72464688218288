import objectFitImages from 'object-fit-images';
import cssVars from 'css-vars-ponyfill';

import './vendor/validityState-polyfill';
import './service-worker';

import fInitCookie from 'js-cookie';
import fInitNews from './news-filtering';
import fInitCatHero from './cat-hero';
import fInitOnboarding from './onboarding';
import fInitModal from './modal';
import fInitHeader from './header';
import fInitCatList from './cat-list';
import fInitCatOverview from './cat-overview';
import fInitCustomSelects from './select';
import fInitCustomDropdowns from './dropdown';
import fInitRangeSlider from './range-slider';
import fInitProductHero from './product-hero';
import fInitMobileFilters from './mobile-filters';
import fInitToggle from './helpers';
import fFormValidation from './form-validation';
import fInitCart from './cart';
import fInitWishlist from './wishlist';
import fInitCounter from './counter';
import fInitCharity from './charity';
import fInitLanguageSwitch from './language-switch';
import fInitFaq from './faq';
import fShowAlert, {fShowDateLimit} from './alert';
import fInitCheckout from './checkout';
import fInitRating from './rating';
import fInitShopInShop from './shop_in_shop';
import fInitPayment from './payment';
import fInitRequirePhoto from './returns';
import fInitShopAssistant from './shop-assistant';

// import Vue from 'vue';
// Vue
// import Onboarding from './../vue/Onboarding.vue';

// new Vue({
//     el: '.app',
//     components: {
//         'onboarding': Onboarding
//     }
// });

export function fInitSelects() {
    fInitCustomSelects();
}

(function fPageLoad() {

    if (document.querySelectorAll('.js-news-results').length) { fInitNews(); }
    if (document.querySelectorAll('.js-cookie').length) { fInitCookie(); }
    if (document.querySelectorAll('.js-cat-hero').length) { fInitCatHero(); }
    if (document.querySelectorAll('.js-onboarding').length) { fInitOnboarding(); }
    if (document.querySelectorAll('.js-cat-list').length) { fInitCatList(); }
    if (document.querySelectorAll('.js-cat-overview').length) { fInitCatOverview(); }
    if (document.querySelectorAll('.modal').length) { fInitModal(); }
    if (document.querySelectorAll('.header').length) { fInitHeader(); }
    if (document.querySelectorAll('.js-select').length) { fInitCustomSelects(); }
    if (document.querySelectorAll('.js-dropdown').length) { fInitCustomDropdowns(); }
    if (document.querySelectorAll('.js-range-slider').length) { fInitRangeSlider(); }
    if (document.querySelectorAll('.js-product-hero').length) { fInitProductHero(); }
    if (document.querySelectorAll('.js-mobile-filter').length) { fInitMobileFilters(); }
    if (document.querySelectorAll('.js-wishlist').length) { fInitWishlist(); }
    if (document.querySelectorAll('.js-counter').length) { fInitCounter(); }
    if (document.querySelectorAll('.js-charity-add, .js-load-donations').length) { fInitCharity(); }
    if (document.querySelectorAll('.js-lang-button').length) { fInitLanguageSwitch(); }
    if (document.querySelectorAll('.js-faq').length) { fInitFaq(); }
    if (document.querySelectorAll('.js-store-date-limit').length) { fShowDateLimit(); }
    if (document.querySelectorAll('.js-checkout').length) { fInitCheckout(); }
    if (document.querySelectorAll('.js-rating').length) { fInitRating(); }
    if (document.querySelectorAll('.js-sis').length) { fInitShopInShop(); }
    if (document.querySelectorAll('.js-payment-radio').length ) { fInitPayment(); }
    if (document.querySelectorAll('#return-reason-id2').length ) { fInitRequirePhoto(); }
    if (document.querySelectorAll('.js-shop-assistant').length ) { fInitShopAssistant(); }
    if (document.querySelectorAll('.js-cart-add').length || document.querySelectorAll('.js-cart-remove').length) { 
        fInitCart(); 
    }
    if (document.querySelectorAll('.js-wishlist-add').length || document.querySelectorAll('.js-wishlist-remove').length) { 
        fInitWishlist(); 
    }

    fInitToggle();

    window.addEventListener('load', () => {
        if (document.querySelectorAll('.js-form-validate').length) { fFormValidation(); }
    });

    // CSS Vars Ponyfill
    function browserCanUseCssVariables() {
        return window.CSS && window.CSS.supports && window.CSS.supports('--fake-var', 0);
    }
    if (!browserCanUseCssVariables()) {
        cssVars({
            // Display transformed CSS
            onComplete: function(cssText, styleNodes, cssVariables, benchmark) {
                let codeElm = document.querySelector('#css-variables-ponyfill');

                // Update <code> tag with CSS result
                codeElm.textContent = cssText;
            }
        });
        objectFitImages();
    }

    // Closest Polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function (s) {
            let el = this;
            do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);
            return null;
        };
    }
}());
