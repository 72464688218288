export default function fInitCatList() {
    let opened = false;
    const catLists = document.querySelectorAll('.cat-list');
    const moreTrigger = document.querySelector('.js-more-cats');

    document.addEventListener('click', function (event) {
        const trigger = event.target.closest('.js-more-cats');

        if (!trigger) return;

        if (opened === true) {
            closeCatList();
        } else {
            openCatList(event);
        }
        });

    function openCatList(event) {
        const el = event.target.closest('.js-more-cats');
        const list = el.closest('.cat-list');
        el.classList.add('opened');
        list.classList.add('opened');

        opened = true;
        toggleText();
    }

    function closeCatList() {
        moreTrigger.classList.remove('opened');

        for (let index = 0; index < catLists.length; index++) {
            const element = catLists[index];
            element.classList.remove('opened');
        }

        opened = false;
        toggleText();
    }

    function toggleText() {
        const closeText = moreTrigger.querySelector('span').getAttribute('data-close-text');
        const openText = moreTrigger.querySelector('span').getAttribute('data-open-text');

        if (opened === true) {
            moreTrigger.querySelector('span').innerHTML = closeText;
        } else {
            moreTrigger.querySelector('span').innerHTML = openText;
        }
    }
}