const Flickity = require('flickity-imagesloaded');

export default function fInitCatHero() {
    const galleryCards = document.querySelectorAll('.cat-hero__gallery__card');
    const flkty = new Flickity('.cat-hero__gallery__slider', {
        wrapAround: true,
        setGallerySize: false,
        imagesLoaded: true,
        autoPlay: 3000,
    });
    const updateStatus = function () {
        for (let index = 0; index < galleryCards.length; index++) {
            const element = galleryCards[index];
            element.classList.remove('active');
        }
        setTimeout(function () {
            galleryCards[flkty.selectedIndex].classList.add('active');
        }, 300);
    };
    flkty.on('change', updateStatus);

    if ( galleryCards[0] ) { galleryCards[0].classList.add('active'); }
}
