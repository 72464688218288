export default function fInitPayment() {
    document.querySelectorAll('.js-payment-radio').forEach(function (el) {
       el.addEventListener('change', function (event) {
           const target = event.target.closest('.js-payment-radio');
           const route = target.dataset.route;
           const input = document.querySelector('input[name="route"]');
           input.value = route;
       })
    });
}
