
// cartGlobals is a global variable, set in inside the cartModal


import alert from './alert';

function fButtonTemplate (productData) {
    let button = document.createElement('button');

    button.setAttribute('class', 'product-hero__content__action__add-to-cart button button--primary js-modal-open js-cart-add');
    button.setAttribute('data-modal-target', 'cartModal');
    button.setAttribute('data-cart-id', productData.id);
    button.setAttribute('data-cart-name', productData.name);
    button.setAttribute('data-cart-thumb', productData.thumb);
    button.setAttribute('data-cart-price', productData.price);
    button.innerHTML = 'Voeg toe aan geschenken';

    return button;  
}

function fProductTemplate (productData) {
    let product = document.createElement('div');
    const template = `
        <div class="product-row__info">
            <img src="${productData.thumb}" alt="Product afbeelding" class="product-row__image">
            <div class="product-row__product">
                <h3>${productData.name}</h3>
                <span class="product-row__product__points"><span class="product-row__product__points__main">${productData.price}</span> punten</span>
                
                <div class="product-row__action">
                    <button class="product-row__remove js-cart-remove"
                            data-cart-id="${productData.id}"
                            data-cart-name="${productData.name}"
                            data-cart-price="${productData.price}">
                        <svg width="13" height="16" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#remove"></use></svg>
                        ${translations.remove}
                    </button>
                </div>
            </div>
        </div>
    `;

    product.setAttribute('class', 'product-row product-row--small');
    product.innerHTML = template;

    return product;
}

function fGetProductData (button) {
    return {
        id: button.getAttribute('data-cart-id'),
        points: document.querySelector('input[name=points]').value,
        name: button.getAttribute('data-cart-name'),
        thumb: button.getAttribute('data-cart-thumb'),
        price: parseInt(button.getAttribute('data-cart-price'), 10),
        totalpoints: parseInt(button.getAttribute('data-cart-totalpoints'), 10),
        remainingpoints: parseInt(button.getAttribute('data-cart-remainingpoints'), 10)
    }
}

function fUpdatePoints (productData, added) {
    const addtocartButtons = Array.from(document.querySelectorAll('.js-cart-add'));
    const remainingPointsInstances = Array.from(document.querySelectorAll('[data-cart-remaining]'));
    const totalPointsInstances = Array.from(document.querySelectorAll('[data-cart-total]'));
    const totalItemsInstances = Array.from(document.querySelectorAll('[data-cart-items]'));
    let remainingPoints;
    let totalPoints;
    let totalItems;

    if (added) {
        remainingPoints = parseInt(cartGlobals.pointsRemaining, 10) - productData.price;
        totalPoints = parseInt(cartGlobals.pointsTotal, 10) + productData.price;
        totalItems = cartGlobals.itemsTotal += 1;
    } else {
        remainingPoints = parseInt(cartGlobals.pointsRemaining, 10) + productData.price;
        totalPoints = parseInt(cartGlobals.pointsTotal, 10) - productData.price;
        totalItems = cartGlobals.itemsTotal -= 1;
    }

    // Remove "can-not-order"
    if (totalItems < cartGlobals.itemsMax) {
        const cantorderInstances = Array.from(document.querySelectorAll('.can-not-order-product'));
        const buttonTemplate = fButtonTemplate(productData);

        cantorderInstances.forEach(instance => {
            instance.parentNode.insertBefore(buttonTemplate, instance);
            instance.remove();
        });
    }

    // Update all points and item instances in the DOM
    remainingPointsInstances.forEach(instance => { instance.innerText = remainingPoints });
    totalPointsInstances.forEach(instance => { instance.innerText = totalPoints });
    totalItemsInstances.forEach(instance => { instance.setAttribute('data-amount', totalItems) });
    
    // Update the global variables
    cartGlobals.pointsRemaining = remainingPoints;
    cartGlobals.pointsTotal = totalPoints;
    cartGlobals.itemsTotal = totalItems;

    // Disable addtocart buttons at max items or too few remaining points
    addtocartButtons.forEach(button => {
        if (totalItems === cartGlobals.itemsMax) {
            button.disabled = true;
        } else {
            if (parseInt(button.getAttribute('data-cart-price'), 10) > remainingPoints) {
                button.disabled = true;
            } else {
                button.disabled = false;
            }
        }
    });
}

function fAdd (cartForm) {
    const points = cartForm.querySelector('input[name=points]');
    const productId = cartForm.querySelector('input[name=product_id]').value;
    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
            try {
                window.location.href = JSON.parse(xhr.response).redirect;
            } catch (e) {
                alert(translations.cartNoPoints);
            }
        }
    };
    xhr.open('POST', '/index.php?route=checkout/cart/add');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    let xhrBody = `product_id=${productId}`;

    if (points) xhrBody += `&points=${points.value}`;

    xhr.send(xhrBody);

}

function fRemove (trigger) {
    const productId = trigger.getAttribute('data-id');

    if (!productId) return;

    const xhr = new XMLHttpRequest();
    
    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
            location.reload();
        }
    };
    xhr.open('POST', '/index.php?route=checkout/cart/remove');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.send(`remove=${productId}`);
}

export default function fInitCart () {
    const cartForms = Array.from(document.querySelectorAll('.js-cart-add'));


    cartForms.forEach(cartForm => {
        cartForm.addEventListener('submit', event => {
            event.preventDefault();
            fAdd(cartForm);
        });
    });

    document.addEventListener('click', event => {
        const removeTrigger = event.target.closest('.js-cart-remove');

        if (removeTrigger) fRemove(removeTrigger);
    });
}