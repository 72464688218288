const Flickity = require('flickity');
require('flickity-imagesloaded');


export default function fInitProductHero() {
    const showMoreEl = document.querySelector('.js-show-more');
    const showLessEl = document.querySelector('.js-show-less');
    const moreContentEl = document.querySelector('.product-hero__content__description .full-text');
    const lessContentEl = document.querySelector('.product-hero__content__description .short-text');
    const thumbnails = document.querySelectorAll('.product-hero__slider__thumbnail');
    let flkty;

    const startProductGallery = function () {
        flkty = new Flickity('.product-hero__slider__gallery', {
            wrapAround: true,
            setGallerySize: false,
            imagesLoaded: true,
            pageDots: false,
            autoPlay: 3000,
        });

        // Listen to thumbnails
        for (let index = 0; index < thumbnails.length; index++) {
            const element = thumbnails[index];
            element.setAttribute('data-slide', index);
        }
    };

    const updateStatus = function () {
        for (let index = 0; index < thumbnails.length; index++) {
            const element = thumbnails[index];
            element.classList.remove('active');
        }
        setTimeout(function () {
            thumbnails[flkty.selectedIndex].classList.add('active');
        }, 300);
    };

    document.addEventListener('click', function (event) {
        if (event.target.closest('.js-show-more')) {
            showMoreEl.classList.add('hidden');
            lessContentEl.classList.add('hidden');
            showLessEl.classList.remove('hidden');
            moreContentEl.classList.remove('hidden');
        }

        if (event.target.closest('.js-show-less')) {
            showMoreEl.classList.remove('hidden');
            lessContentEl.classList.remove('hidden');
            showLessEl.classList.add('hidden');
            moreContentEl.classList.add('hidden');
        }

        if (event.target.closest('.product-hero__slider__thumbnail')) {
            const el = event.target.closest('.product-hero__slider__thumbnail');
            const index = el.getAttribute('data-slide');

            flkty.select(index);
        }
    });
    startProductGallery();            
    flkty.on('change', updateStatus);

    function openCartModal() {
        this.$parent.openCartModal();
    }
}