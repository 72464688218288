import alert from './alert';

export default function fInitRating() {
    const form = document.querySelector('#rating-form');
    const submitButton = document.querySelector('.js-submit-rating');
    const url = form.action;

    const stars = document.querySelectorAll('.rate label');
    const valueField = document.querySelector('.rating__selected-value');

    for (let index = 0; index < stars.length; index++) {
        const element = stars[index];
        element.addEventListener('click', function () {
            valueField.value = element.dataset.value;
        });
    }

    submitButton.addEventListener('click', () => {
        if ( valueField.value === 'undefined' || valueField.value.length === 0 ) return alert(translations.noRating);

        const xhr = new XMLHttpRequest();

        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                alert(translations.ratingSend);
                document.querySelector('.checkout__aside').innerHTML = '';
            }
        };

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        xhr.send(`value=${valueField.value}`);
    });
}