// import Vue from 'vue';
// import Vuelidate from 'vuelidate';
// import { required, minLength, email } from 'vuelidate/lib/validators';
import {TimelineMax} from 'gsap';

// import Modal from '~/components/Modal.vue';

// Vue.use(Vuelidate);

export default function fInitOnboarding() {
    const forms = document.querySelectorAll('form.js-no-submit');
    for (const form of forms) {
        form.addEventListener('submit', function (event) {
            event.preventDefault();
        })
    }
    fIntroAnimation();

    if ( document.querySelectorAll('.js-skip-first').length ) fShowVideoAnimation(new TimelineMax());

    document.addEventListener('click', function (event) {
        const trigger = event.target.closest('.js-eye');
        if (!trigger) return;
        fTogglePassword();
    });
    document.addEventListener('click', function (event) {
        const trigger = event.target.closest('.js-continue-to-video');
        if (!trigger) return;
        fSubmitForm(trigger);
    });

    function fTogglePassword() {
        const passwordEl = document.querySelector('.js-password');
        const eyeEl = document.querySelector('.js-eye');
        if (passwordEl.type === 'password') {
            passwordEl.type = 'text';
            eyeEl.classList.add('active');
        } else {
            passwordEl.type = 'password';
            eyeEl.classList.remove('active');
        }
    };

    function fIntroAnimation() {
        const ornaments = document.querySelector('.onboarding__ornaments');
        const smallOrnaments = document.querySelectorAll('.onboarding__theme-image');

        const timeline = new TimelineMax();

        timeline.to(ornaments, 2, {
            y: 0,
            autoAlpha: 1,
            ease: 'Power2.easeOut'
        })
            .to(smallOrnaments, 3, {
                y: 0,
                autoAlpha: 1,
                ease: 'Power2.easeOut'
            }, '-=1.6');
    };

    function fContinueToVideo() {
        // Elements
        const card = document.querySelector('.onboarding__card');
        const ornaments = document.querySelector('.onboarding__ornaments');
        const smallOrnaments = document.querySelectorAll('.onboarding__theme-image');
        const copyright = document.querySelector('.onboarding__copyright');
        const affiliated = document.querySelector('.onboarding__affiliated');

        const firstName = document.querySelector('.js-first-name').value;
        const firstNameHolder = document.querySelector('.js-firstname-holder');

        if (firstName.length !== 0) {
            firstNameHolder.innerHTML = ' ' + firstName;
        }

        const timeline = new TimelineMax();

        timeline.to(card, 0.8, {
            y: -70,
            autoAlpha: 0,
            ease: 'Power2.easeIn'
        })
            .to(ornaments, 1.4, {
                y: -100,
                autoAlpha: 0,
                ease: 'Power1.easeInOut'
            }, '-=.45')
            .to(smallOrnaments, 1.4, {
                y: -100,
                autoAlpha: 0,
                ease: 'Power1.easeInOut'
            }, '-=1.3')
            .to(copyright, 0.2, {
                y: -20,
                autoAlpha: 0,
                ease: 'Power1.easeInOut'
            }, '-=1.3')
            .to(affiliated, 0.2, {
                y: -20,
                autoAlpha: 0,
                ease: 'Power1.easeInOut'
            }, '-=1.2');
        fShowVideoAnimation(timeline);
    }

    function fShowVideoAnimation(timeline) {
        const welcome = document.querySelector('.onboarding__welcome');
        const welcomeTitle = document.querySelector('.onboarding__welcome__header');
        const welcomeText = document.querySelector('.onboarding__welcome__text');
        const playButton = document.querySelector('.onboarding__welcome__shape__play');
        const shape = document.querySelector('.onboarding__welcome__shape__video');
        const pine = document.querySelector('.onboarding__welcome__shape__theme-image--el1');
        const leaf = document.querySelector('.onboarding__welcome__shape__theme-image--el2');
        const bell = document.querySelector('.onboarding__welcome__shape__theme-image--el3');
        const plume = document.querySelector('.onboarding__welcome__shape__theme-image--el4');
        const gift = document.querySelector('.onboarding__welcome__shape__theme-image--el5');
        const ball = document.querySelector('.onboarding__welcome__shape__theme-image--el6');

        timeline.to(welcome, 0.75, {
            autoAlpha: 1,
            display: 'block',
            ease: 'Power1.easeInOut'
        })
            .to(pine, 0.75, {
            opacity: 1,
            y: 0,
            scale: 1,
            ease: 'Power1.easeOut'
            }, '-=0.5')
            .to(leaf, 0.75, {
                opacity: 1,
                y: 0,
                scale: 1,
                ease: 'Power1.easeOut'
            }, '-=0.6')
            .to(shape, 0.75, {
                opacity: 1,
                scale: 1,
                rotation: 0,
                ease: 'Power1.easeOut'
            }, '-=0.5')
            .to(playButton, 1, {
                opacity: 1,
                rotation: 0,
                scale: 1
            }, '-=0.5')
            .to(welcomeTitle, 0.75, {
                opacity: 1,
                y: 0,
                ease: 'Power1.easeOut'
            }, '-=0.75')
            .to(welcomeText, 0.75, {
                opacity: 1,
                y: 0,
                ease: 'Power1.easeOut'
            }, '-=0.6')
            .to(bell, 1.2, {
                opacity: 1,
                y: 0,
                scale: 1,
                ease: 'Power1.easeOut'
            }, '-=0.4')
            .to(plume, 1.2, {
                opacity: 0.6,
                y: 0,
                scale: 0.8,
                ease: 'Power1.easeOut'
            }, '-=1.4')
            .to(ball, 1.2, {
                opacity: 1,
                y: 0,
                scale: 1,
                ease: 'Power1.easeOut'
            }, '-=1.4')
            .to(gift, 1.2, {
                opacity: 1,
                y: 0,
                x: '250%',
                scale: 1,
                ease: 'Power1.easeOut'
            }, '-=1.4');
    }

    function fOpenVideoModal() {
        const trigger = document.querySelector('.onboarding__welcome__shape');
        // this.$refs.modal.getModal(trigger);
    }

    function fSubmitForm(trigger) {
        const form = trigger.parentElement;
        const firstname = form.querySelector('input[name=firstname]').value;
        const email = form.querySelector('input[name=email]').value;

        const xhr = new XMLHttpRequest();

        xhr.onload = function () {
            if (xhr.response && xhr.status >= 200 && xhr.status < 300) {
                const json = JSON.parse(xhr.response);

                if (!json.errors) {
                    fContinueToVideo()
                } else {
                    fShowError(trigger, json.errors)
                }
            }
        };

        xhr.open('POST', '/index.php?route=account/edit/saveIntro');
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        xhr.send(`firstname=${firstname}&email=${email}`);
    }

    function fShowError(trigger, errors) {
        const error = document.querySelector('.form__error');
        let msg = [];

        if (typeof errors.email !== 'undefined') msg.push(errors.email);
        if (typeof errors.firstname !== 'undefined') msg.push(errors.firstname);
        if (typeof errors.method !== 'undefined') msg.push(errors.method);

        error.innerHTML = msg.join(', ');
    }
};