export default function fShowAlert (message, type, duration = 3) {
    const alert = document.createElement('div');

    alert.classList.add('alert');
    alert.innerText = message;

    if (type) {
        if (type === 'removed') {
            alert.classList.add('alert--removed');
            alert.innerHTML = `
                <svg id="remove" class="alert__icon" xmlns="http://www.w3.org/2000/svg">
                    <g fill-rule="evenodd">
                        <path d="M1.297 16a.433.433 0 0 1-.432-.432V3.459c0-.238.194-.432.432-.432h10.379c.238 0 .432.194.432.432v12.109a.433.433 0 0 1-.432.432H1.297zm9.946-.865V3.892H1.73v11.243h9.513zM12.541.865c.238 0 .432.194.432.432V3.46a.433.433 0 0 1-.432.433H.432A.433.433 0 0 1 0 3.459V1.297C0 1.06.194.865.432.865H3.28L4.02.127A.432.432 0 0 1 4.324 0H8.65c.114 0 .224.046.305.127l.739.738h2.848zm-3.027.865a.432.432 0 0 1-.306-.127L8.47.865H4.503l-.738.738a.432.432 0 0 1-.306.127H.865v1.297h11.243V1.73H9.514z"></path>
                        <path d="M3.46 5.838v7.351a.433.433 0 0 0 .864 0V5.838a.433.433 0 0 0-.865 0zM6.054 5.838v7.351a.433.433 0 0 0 .865 0V5.838a.433.433 0 0 0-.865 0zM8.649 5.838v7.351a.433.433 0 0 0 .865 0V5.838a.433.433 0 0 0-.865 0z"></path>
                    </g>
                </svg>
                ${message}
            `;
        } else if (type === 'added') {
            alert.classList.add('alert--added');
            alert.innerHTML = `
                <svg id="favourite" class="alert__icon" viewBox="0 0 28 27" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2 2.534C14.531.972 16.007.2 18.257.2c4.644 0 7.513 3.037 7.513 7.966 0 4.067-2.772 7.887-7.87 12.087-1.107.913-2.008 1.604-3.87 2.995l-.547.408a.725.725 0 0 1-.869 0l-.544-.407C3.86 17.12.325 13.285.325 8.167.325 3.238 3.195.2 7.838.2c2.25 0 3.727.772 5.057 2.334l.152.18.153-.18z" fill-rule="nonzero"></path>
                </svg>
                ${message}
            `;
        }
    } else {
        alert.innerHTML = message;
    }

    document.body.appendChild(alert);

    setTimeout(() => {
        alert.classList.add('is-active');
    }, 100);

    setTimeout(() => {
        alert.classList.remove('is-active');

        setTimeout(() => {
            alert.remove();
        }, 300);
    }, duration * 1000);
}

export function fShowDateLimit() {
    const text = document.querySelector('.js-store-date-limit').getAttribute('data-text');
    fShowAlert(text, 'time', 10);
}