import fBodyScrollEnabled from './disable-scroll';

export default function fInitMobileNav() {
    const mobileNavItems = document.querySelectorAll('.mobile-nav__list__item');
    const mobileSubLevelHeaders = document.querySelectorAll('.mobile-nav__sub-level__header');
    let isAnimating = false;

    // Open Mobile Nav
    document.addEventListener('click', function(){
        const trigger = event.target.closest('.js-mobile-nav-toggle');
        if (!trigger) return;
        openMenu(event);
    });

    // Close Mobile Nav
    document.addEventListener('click', function(){
        const trigger = event.target.closest('.js-mobile-nav-close');
        if (!trigger) return;
        closeMenu(event);
    });

    for (let index = 0; index < mobileNavItems.length; index++) {
        const element = mobileNavItems[index];

        element.addEventListener('click', function (event) {
            const trigger = event.target.closest('.mobile-nav__list__link');
            if (!trigger) return;

            const listItem = trigger.parentElement;
            const subLevel = listItem.querySelector('.mobile-nav__sub-level');
            listItem.classList.add('opened');
            subLevel.classList.add('opened');
        });
    }

    for (let index = 0; index < mobileSubLevelHeaders.length; index++) {
        mobileSubLevelHeaders[index].addEventListener('click', function (event) {
            const trigger = event.target.closest('.mobile-nav__sub-level__header');
            if (!trigger) return;

            const openSubLevels = document.querySelectorAll('.mobile-nav__sub-level.opened');
            
            for (let index = 0; index < openSubLevels.length; index++) {
                const element = openSubLevels[index];
                element.classList.remove('opened');
            }
        });
    }

    function openMenu() {
        if (isAnimating) return;
        isAnimating = true;

        const oTimeline = new TimelineMax();
        const mobileNav = document.querySelector('.mobile-nav');
        const mobileNavCatListItems = document.querySelectorAll('.mobile-nav__cat-list-wrapper li');
        const mobileNavLinks = document.querySelectorAll('.mobile-nav__link');

        mobileNav.classList.add('is-active');

        // Animate
        oTimeline.timeScale(2.5);
        oTimeline
        .set(mobileNav, {
            display: 'block'
        }).to(mobileNav, 1, {
            y: 0,
            x: 0,
            autoAlpha: 1,
            ease: 'Power2.easeOut'
        })
        .staggerTo(mobileNavCatListItems, 2, {
            autoAlpha: 1,
            ease: 'Power2.easeOut'
        }, 0.1)
        .to(mobileNavLinks, 1.5, {
            autoAlpha: 1,
            ease: 'Power2.easeOut',
            onComplete: animationCallback
        }, '-=1.5');

        fBodyScrollEnabled(false);
    }

    function closeMenu() {
        if (isAnimating) return;
        isAnimating = true;

        const oTimeline = new TimelineMax();
        const mobileNav = document.querySelector('.mobile-nav');
        const mobileNavCatListItems = document.querySelectorAll('.mobile-nav__cat-list-wrapper li');
        const mobileNavLinks = document.querySelectorAll('.mobile-nav__link');

        function animationCallback() {
            isAnimating = false;
        }

        mobileNav.classList.remove('is-active');

        // Animate out
        oTimeline.timeScale(2);
        oTimeline
        .to(mobileNavLinks, 0.5, {
            autoAlpha: 0
        })
        .staggerTo(mobileNavCatListItems, 2, {
            autoAlpha: 0,
            ease: 'Power2.easeOut'
        }, 0.1, '-=0.35')
        .to(mobileNav, 1, {
            autoAlpha: 0,
            y: 0,
            x: 0,
            ease: 'Power2.easeOut'
        }, '-=2')
        .set(mobileNav, {
            display: 'none',
            onComplete: animationCallback
        });

        fBodyScrollEnabled(true);
    }

    function animationCallback() {
        isAnimating = false;
    }
}