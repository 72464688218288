import flatpickr from 'flatpickr';

export default function fInitCheckout() {
    const checkoutContainer = document.querySelector('.js-checkout');
    const dhlPostcode = document.querySelector('input[name="postcode_dhl"]');
    const addressAndHousenr = Array.from(document.querySelectorAll('input[name=postcode], input[name=housenr]'));
    const teamSelect = document.querySelector('.js-team-id');

    const delivery = document.querySelector('#delivery');
    const dhlPickup = document.querySelector('#dhlPickup');
    const deliveryLocation = document.querySelector('#deliveryLocation');

    if (delivery) {
        delivery.classList.add('hidden');
    }
    if (dhlPickup) {
        dhlPickup.classList.add('hidden');
    }

    if (deliveryLocation) {
        deliveryLocation.classList.add('hidden');
    }

    let step = 1;
    fDisableCopyPaste();
    document.addEventListener('click', function (event) {
        const nextStepTrigger = event.target.closest('.js-next-step');
        const prevStepTrigger = event.target.closest('.js-prev-step');
        const dhlServicePoint = event.target.closest('.js-dhl-service');
        const deliverToHome = event.target.closest('.js-deliver-to-home');
        const deliveryAsap = event.target.closest('.js-delivery-asap');
        const deliveryChooseDate = event.target.closest('.js-delivery-choose-date');
        const deliveryLocation = event.target.closest('.js-deliver-to-location');

        if (nextStepTrigger) step = fNextStep(checkoutContainer, step);
        if (prevStepTrigger) step = fPrevStep(checkoutContainer, step);
        if (dhlServicePoint) fShowDHL();
        if (deliverToHome) fShowDelivery();
        if (deliveryAsap) fShowDeliveryAsap();
        if (deliveryChooseDate) fShowDeliveryChoose();
        if (deliveryLocation) fShowDeliveryLocation();
    });

    if ( teamSelect !== null ) {
        teamSelect.addEventListener('change', function() {
            fTeamSelected(teamSelect);
        });
    }

    addressAndHousenr.forEach(input => {
        input.addEventListener('keyup', () => {
            fFetchAddressData(checkoutContainer);
        })
    });

    if ( dhlPostcode !== null) {
        dhlPostcode.addEventListener('keyup', event => {
            const postcode = dhlPostcode.value.replace(' ', '');

            if (postcode.length <= 4) return;

            const xhr = new XMLHttpRequest();

            xhr.onload = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                    const html = xhr.response;
                    const div = document.querySelector('#dhlPickup__ajax');
                    div.innerHTML = html;
                }
            };
            xhr.open('GET', `index.php?route=checkout/shipping_method/pickuppoints&postcode=${postcode}`);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            xhr.send();
        });
    }

    let someDate = new Date();
    someDate.setDate(someDate.getDate() + 2);
    const fp = flatpickr('#home_delivery_date', {
        dateFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        minDate: someDate.toUTCString(),
        disable: [
            function (date) {
                // return true to disable
                // zondag en maandag (!)
                return (
                    date.toLocaleDateString() === '25-12-2020' ||
                    date.toLocaleDateString() === '26-12-2020' ||
                    date.toLocaleDateString() === '31-12-2020' ||
                    date.toLocaleDateString() === '1-1-2021' ||
                    date.toLocaleDateString() === '5-4-2021' ||
                    date.toLocaleDateString() === '27-4-2021' ||
                    date.toLocaleDateString() === '13-5-2021' ||
                    date.toLocaleDateString() === '24-5-2021' ||
                    date.toLocaleDateString() === '25-12-2021' ||
                    date.toLocaleDateString() === '26-12-2021' ||
                    date.getDay() === 0 || date.getDay() === 1
                );

            }
        ],
        locale: {
            firstDayOfWeek: 1 // start week on Monday
        }
    });

    const radio1 = document.querySelector('#radio1');
    const radio3 = document.querySelector('#radio3');

    if (radio1) {
        radio1.checked = true;
        fShowDelivery();
    } else if (radio3) {
        radio3.checked = true;
        fShowDeliveryLocation();
    }

}

function fTeamSelected(teamSelect) {
    if ( teamSelect.value === '' ) return;
    const selected = teamSelect.querySelector('option[value="' + teamSelect.value + '"]');
    const name = selected.innerHTML;
    const postcode = selected.dataset.postcode;
    const houseNr = selected.dataset.housenr;
    const houseNrAddition = selected.dataset.housenrAddition;
    const address1 = selected.dataset.address1;
    const city = selected.dataset.city;
    const country = selected.dataset.country;

    if (typeof name !== 'undefined') {
        document.querySelector('#dlName').innerHTML = name;
    }
    if (typeof postcode !== 'undefined') {
        document.querySelector('#dlPostcode').innerHTML = postcode;
        document.querySelector('#id_postcode').value = postcode;
    }
    if (typeof houseNr !== 'undefined') {
        document.querySelector('#dlHouseNr').innerHTML = houseNr;
        document.querySelector('#id_housenr').value = houseNr;
    }
    if (typeof houseNrAddition !== 'undefined') {
        document.querySelector('#dlHourNrAdd').innerHTML = houseNrAddition;
        document.querySelector('#id_housenr_addition').value = houseNrAddition;
    }
    if (typeof address1 !== 'undefined') {
        document.querySelector('#dlAddress').innerHTML = address1;
        document.querySelector('#id_adres').value = address1;
    }
    if (typeof city !== 'undefined') {
        document.querySelector('#dlCity').innerHTML = city;
        document.querySelector('#id_plaats').value = city;
    }
    if (typeof country !== 'undefined') {
        document.querySelector('#dlCountry').innerHTML = country;
        document.querySelector('#country_text').value = country;
    }
}

function fDisableCopyPaste() {
    const email = document.querySelector('[name="email"]');
    const email2 = document.querySelector('[name="email2"]');

    email.addEventListener('cut', fPreventDefault);
    email.addEventListener('copy', fPreventDefault);
    email.addEventListener('paste', fPreventDefault);

    email2.addEventListener('cut', fPreventDefault);
    email2.addEventListener('copy', fPreventDefault);
    email2.addEventListener('paste', fPreventDefault);
}

function fPreventDefault(event) {
    event.preventDefault();
}

function fShowDeliveryAsap() {
    const deliveryAsap = document.querySelector('#home-delivery-div');
    const deliveryInput = document.querySelector('input[name=home_delivery_date]');

    deliveryAsap.classList.add('hidden');
    removeRequired(deliveryInput);
}

function fShowDeliveryChoose() {
    const deliveryAsap = document.querySelector('#home-delivery-div');
    const deliveryInput = document.querySelector('input[name=home_delivery_date]');

    deliveryAsap.classList.remove('hidden');
    setRequired(deliveryInput);
}

function fFetchAddressData(container) {
    const postcode = document.querySelector('input[name=postcode]');
    const number = document.querySelector('input[name=housenr]').value;

    if (!(postcode.value.length > 5 && number !== '')) return;

    const url = `index.php?route=checkout/checkout/postcode&postcode=${postcode.value}&housenr=${number}`;

    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
            const response = JSON.parse(xhr.response);

            if ( response.hasOwnProperty('error') ) {
                postcode.setCustomValidity(translations.cantDeliver);
                postcode.reportValidity();
                return; // We don't fill in the address and city (because we don't have it returned)
            } else { // We need to let the browser know that this field is correct (again).
                postcode.setCustomValidity('');
            }

            const street = document.querySelector('input[name=address_1]');
            const city = document.querySelector('input[name=city]');

            street.value = response.straat;
            city.value = response.plaats;
        }
    };

    xhr.open('GET', url);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.send();
}

function fShowDHL() {
    const delivery = document.querySelector('#delivery');
    const dhlPickup = document.querySelector('#dhlPickup');
    const deliveryLocation = document.querySelector('#deliveryLocation');
    const postcodeInput = document.querySelector('input[name=postcode]');
    const housenrInput = document.querySelector('input[name=housenr]');
    const streetInput = document.querySelector('input[name=address_1]');
    const cityInput = document.querySelector('input[name=city]');
    const postcodeDhlInput = document.querySelector('input[name=postcode_dhl]');
    const teamIdInput = document.querySelector('[name=team_id]');

    delivery.classList.add('hidden');
    dhlPickup.classList.remove('hidden');

    if ( deliveryLocation ) {
        deliveryLocation.classList.add('hidden');
    }


    removeRequired(postcodeInput);
    removeRequired(housenrInput);
    removeRequired(streetInput);
    removeRequired(cityInput);
    removeRequired(teamIdInput);
    setRequired(postcodeDhlInput);
}

function fShowDelivery() {
    const delivery = document.querySelector('#delivery');
    const dhlPickup = document.querySelector('#dhlPickup');
    const deliveryLocation = document.querySelector('#deliveryLocation');
    const postcodeInput = document.querySelector('input[name=postcode]');
    const housenrInput = document.querySelector('input[name=housenr]');
    const streetInput = document.querySelector('input[name=address_1]');
    const cityInput = document.querySelector('input[name=city]');
    const postcodeDhlInput = document.querySelector('input[name=postcode_dhl]');
    const teamIdInput = document.querySelector('[name=team_id]');


    const countryId = document.querySelector('#country_id');
    if ( countryId ) {
        setRequired(countryId);
        countryId.setAttribute('name', 'country_id');
    }

    const countryText = document.querySelector('#country_text');
    if ( countryText ) {
        countryText.setAttribute('disabled', 'disabled');
    }

    delivery.classList.remove('hidden');
    dhlPickup.classList.add('hidden');

    if ( deliveryLocation ) {
        deliveryLocation.classList.add('hidden');
    }

    setRequired(postcodeInput);
    setRequired(housenrInput);
    setRequired(streetInput);
    setRequired(cityInput);

    postcodeInput.value = '';
    housenrInput.value = '';
    streetInput.value = '';
    cityInput.value = '';

    removeRequired(postcodeDhlInput);
    if ( teamIdInput ) removeRequired(teamIdInput);
}

function fShowDeliveryLocation() {
    const delivery = document.querySelector('#delivery');
    const dhlPickup = document.querySelector('#dhlPickup');
    const deliveryLocation = document.querySelector('#deliveryLocation');
    const postcodeInput = document.querySelector('input[name=postcode]');
    const housenrInput = document.querySelector('input[name=housenr]');
    const streetInput = document.querySelector('input[name=address_1]');
    const cityInput = document.querySelector('input[name=city]');
    const postcodeDhlInput = document.querySelector('input[name=postcode_dhl]');
    const teamIdInput = document.querySelector('[name=team_id]');

    const countryId = document.querySelector('#country_id');
    if ( countryId ) {
        removeRequired(countryId);
        countryId.removeAttribute('name');
    }

    const countryText = document.querySelector('#country_text');
    if ( countryText ) {
        countryText.removeAttribute('disabled');
    }

    fTeamSelected(document.querySelector('.js-team-id'));

    if (delivery) {
        delivery.classList.add('hidden');
    }

    if (dhlPickup) {
        dhlPickup.classList.add('hidden');
    }

    if (deliveryLocation) {
        deliveryLocation.classList.remove('hidden');
    }

    const notRequiredInputs = document.querySelectorAll('#delivery input');

    if ( notRequiredInputs.length > 0 ) {
        notRequiredInputs.forEach(function (el) {
            removeRequired(el);
        })
    }

    setRequired(postcodeInput);
    setRequired(housenrInput);
    setRequired(streetInput);
    setRequired(cityInput);
    removeRequired(postcodeDhlInput);
    removeRequired(teamIdInput);
}

function fPrevStep(container, step) {
    const currStepDot = container.querySelector(`.checkout-steps__step[data-step="${step}"]`);
    const prevStepDot = container.querySelector(`.checkout-steps__step[data-step="${step - 1}"]`);
    const allSteps = container.querySelectorAll('.checkout__step');

    currStepDot.classList.remove('active');

    for (const steps of allSteps) {
        steps.classList.remove('is-active');
    }

    prevStepDot.querySelector('.checkout-steps__number').innerHTML = (step - 1).toString(10);

    fShowStep(container, step - 1);
    return step - 1;
}

function fNextStep(container, step) {
    if (!fCheckValidity(container)) {
        return step;
    }

    if (fHasNextStep(container, step)){
        return fShowNextStep(container, step);
    } else {
        fHideSubmitButton(container);
        fSubmitForm(container);
    }
}

function fHideSubmitButton(container) {
    const submitButton = container.querySelector('.checkout__card__footer__next');
    const waitText = document.createElement('span');
    waitText.innerHTML = translations.checkoutWait;
    waitText.classList.add('checkout__card__footer__next');
    submitButton.parentNode.replaceChild(waitText, submitButton);
}

function fHasNextStep(container, step) {
    return container.querySelectorAll(`.checkout__step[data-step="${step + 1}"]`).length > 0;
}

function fShowNextStep(container, step) {
    fFinishStep(container, step);
    fShowStep(container, step + 1);
    return step + 1;
}

function fFinishStep(container, step) {
    const currStepDot = container.querySelector(`.checkout-steps__step[data-step="${step}"]`);
    const allSteps = container.querySelectorAll('.checkout__step');

    currStepDot.classList.remove('active');
    currStepDot.querySelector('.checkout-steps__number').innerHTML = '&#x2713;';

    for (const steps of allSteps) {
        steps.classList.remove('is-active');
    }
}

function fShowStep(container, step) {
    const nextStepDot = container.querySelector(`.checkout-steps__step[data-step="${step}"]`);
    const nextStep = container.querySelector(`.checkout__step[data-step="${step}"]`);

    nextStepDot.classList.add('active');
    nextStep.classList.add('is-active');
}

function fCheckValidity(container) {
    const select = container.querySelector('#BPE_issuer');
    const emailInput = container.querySelector('input[name=email]');
    const emailRepeatInput = container.querySelector('input[name=email2]');
    const homeDeliveryDateInput = container.querySelector('input[name=home_delivery_date]');
    const houseNumberInput = container.querySelector('input[name=housenr]');
    const postcodeInput = container.querySelector('input[name=postcode]');
    const dhlRadio = container.querySelector('.js-dhl-service input[name=shipping_method]');
    const firstNameInput = container.querySelector('.input[name=firstname]');
    const email = emailInput.value;
    const lastRadio = container.querySelector('#buckaroo_paypal');
    const idealDropdown = container.querySelector('select[name=BPE_issuer]');

    emailRepeatInput.setCustomValidity('');
    if (homeDeliveryDateInput) {
        homeDeliveryDateInput.setCustomValidity('');
    }

    if (lastRadio) {
        lastRadio.setCustomValidity('');
    }

    if (idealDropdown) {
        idealDropdown.setCustomValidity('');
    }

    if (emailInput) {
        emailInput.setCustomValidity('');
    }

    if (houseNumberInput) {
        houseNumberInput.setCustomValidity('');
    }

    if (postcodeInput) {
        postcodeInput.setCustomValidity('');
    }

    if (dhlRadio) {
        dhlRadio.setCustomValidity('');
    }

    if (select) {
        select.removeAttribute('required');
    }

    // The firstname should not start and end with spaces
    if (firstNameInput) {
        firstNameInput.value = firstNameInput.value.trim();
    }

    if (!container.checkValidity()) {
        fSimulateSubmitClick(container);
        if (select) {
            select.setAttribute('required', 'required');
        }
        return false;
    }

    if ( fCheckEmailBlocked(email) ) {
        emailInput.setCustomValidity(translations.emailBlocked);
        fSimulateSubmitClick(container);
        return false;
    }

    const deliverToHomeChecked = container.querySelector('[name=shipping_method]') && container.querySelector('[name=shipping_method]').checked;

    if (deliverToHomeChecked && houseNumberInput && fCheckHouseNr(houseNumberInput.value)) {
        houseNumberInput.setCustomValidity(translations.houseNumberInvalid);
        fSimulateSubmitClick(container);
        return false;
    }

    if (dhlRadio && fCheckDhl(dhlRadio)) {
        dhlRadio.setCustomValidity(translations.dhlInvalid);
        fSimulateSubmitClick(container);
        return false;
    }

    // Only checks Dutch and Belgian postal codes
    if ( deliverToHomeChecked && postcodeInput && fCheckPostcode(postcodeInput.value) ) {
        postcodeInput.setCustomValidity(translations.postcodeInvalid);
        fSimulateSubmitClick(container);
        return false;
    }

    if (email !== emailRepeatInput.value) {
        emailRepeatInput.setCustomValidity(translations.emailsDiffer);
        fSimulateSubmitClick(container);
        return false;
    }

    if (homeDeliveryDateInput && homeDeliveryDateInput.hasAttribute('required') && homeDeliveryDateInput.value.length < 1) {
        homeDeliveryDateInput.removeAttribute('readonly');
        fSimulateSubmitClick(container);
        homeDeliveryDateInput.setAttribute('readonly', 'readonly');
        return false;
    }

    // Only checks Dutch and Belgian postal codes
    if ( fCheckPaymentMethod(container) ) {
        lastRadio.setCustomValidity(translations.paymentMethodInvalid);
        fSimulateSubmitClick(container);
        return false;
    }

    if (fCheckIdeal(container)) {
        idealDropdown.setCustomValidity(translations.BPEIssuerInvalid);
        fSimulateSubmitClick(container);
        return false;
    }

    if (select) {
        select.setAttribute('required', 'required');
    }
    return true;
}

function fCheckPaymentMethod(container) {
    // If the second checkout step is not active, "ignore" this step
    if ( !container.querySelector('.checkout__step.is-active[data-step="2"]') ) {
        return false;
    }

    // The second checkout step (additional payment) is active, check if filled in correctly
    const selectedRadio = container.querySelector('input[name=payment_method]:checked');

    // If there is no payment method selected
    if ( !selectedRadio ) {
        return true;
    }

    return false;
}

function fCheckIdeal(container) {
    if (
        !container.querySelector('.checkout__step.is-active[data-step="2"]')
        || !container.querySelector('input[name=payment_method]:checked')
    ) {
        return false;
    }

    if (container.querySelector('input[name=payment_method]:checked').id === 'buckaroo_ideal') {
        const select = document.querySelector('select[name=BPE_issuer]');
        const options = select.querySelectorAll('option');

        if ( options[select.selectedIndex].value === '' ) {
            return true;
        }
    }

    return false;
}
function fCheckDhl(dhlRadio) {
    if ( !dhlRadio.checked ) {
        return false;
    }

    return document.querySelectorAll('input[name=pickup_point_id][checked]').length < 1;
}

function fCheckPostcode(postcode) {
    // Check if the postcode is dutch or from belgium
    const country = document.querySelector('select[name=country_id]').value;

    // 150 == Netherlands
    if ( country === '150' ) {
        return !(/[0-9]{4}[A-Za-z]{2}/.test(postcode));
    }

    // 21 == Belgium
    if ( country === '21' ) {
        return !(/([0-9]){4}/.test(postcode));
    }

    return false
}

function fCheckHouseNr(housenr) {
    return !(/\d/.test(housenr)); // Test whether the house field contains at least one number
}

function fCheckEmailBlocked(email) {
    const xhr = new XMLHttpRequest();

    // Synchronous xhr call.
    xhr.open('GET', `index.php?route=checkout/checkout/blockedEmailDomain&email=${email}`, false);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.send();
    return JSON.parse(xhr.response).blocked;
}


function fSubmitForm(container) {
    container.submit();
}

function fSimulateSubmitClick(container) {
    let tmpSubmit = document.createElement('button');
    container.appendChild(tmpSubmit);
    tmpSubmit.click();
    container.removeChild(tmpSubmit);
}

function setRequired(element) {
    element.setAttribute('required', 'required');
}

function removeRequired(element) {
    element.removeAttribute('required');
}