let component = undefined;
let categoryId = undefined;
let categoryList = undefined;
let minPoints = undefined;
let maxPoints = undefined;
let sortOrder = undefined;
let sortDir = undefined;
let search = '';
let scrollPos = undefined;

function fFilterProducts (filter, categoryId, categoryList) {
    const filterData = [];
    const xhr = new XMLHttpRequest();
    const search = document.querySelector('input[name=search]').value;

    fShowLoader();

    fUpdateFilterData(minPoints, maxPoints);

    document.querySelectorAll('.js-filters input[type=checkbox], .js-mobile-filter input[type=checkbox]').forEach(checkbox => {
       if ( checkbox.checked ) filterData.push(checkbox.value);
    });

    xhr.onload = function () {
        if (xhr.response && xhr.status >= 200 && xhr.status < 300) {
            categoryList.innerHTML = JSON.parse(xhr.response).html;
            fHideLoader();

            if ( typeof scrollPos !== 'undefined' ) {
                document.documentElement.scrollTop = document.body.scrollTop = scrollPos;
                scrollPos = undefined;
            }
        }
    };

    let action = `/index.php?route=product/category&path=${categoryId}`;

    if ( filterData.length > 0 ) action += `&filter=${filterData.join(',')}`;

    if ( typeof minPoints !== 'undefined' ) action += `&range_from=${minPoints}`;
    if ( typeof maxPoints !== 'undefined' ) action += `&range_to=${maxPoints}`;
    if ( typeof sortOrder !== 'undefined' ) action += `&sort=${sortOrder}`;
    if ( typeof sortDir !== 'undefined' ) action += `&order=${sortDir}`;

    window.history.pushState({}, document.title, action);
    let body = `is_result_partial=1&check_categories=${categoryId}`;
    if ( search.length ) body += `&search=${search}`;

    xhr.open('POST', action);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.send(body);

    return filterData;
}

function fUpdateFilterData(minimal, maximal) {
    if ( typeof minimal !== 'undefined' ) {
        document.querySelector('#filter_points_low').innerHTML = minimal;
    }

    if ( typeof maximal !== 'undefined' ) {
        document.querySelector('#filter_points_high').innerHTML = maximal;
    }
}

function fExtendFilter(event) {
    event.style.display = 'none';

    for ( let sibling = event.parentNode.firstChild; sibling; sibling = sibling.nextSibling ) {
        if (sibling.nodeType !== 1 || sibling === event) continue;
        sibling.classList.remove('hidden');
    }
}

function fExtendProducts() {
    const products = document.querySelectorAll('.product-hidden');

    for (const product of products) {
        product.classList.remove('product-hidden');
    }

    document.querySelector('.js-extend-products').remove();
}

export default function fInitCatOverview () {
    component = document.querySelector('.js-cat-overview');
    categoryId = component.getAttribute('data-category-id');
    categoryList = component.querySelector('[data-category-list]');
    search = document.querySelector('input[name=search]').value;

    component.addEventListener('change', event => {
        const filter = event.target.closest('.product-filters__checkbox');

        if (filter) { fFilterProducts(filter, categoryId, categoryList);}
    });

    document.addEventListener('click', event => {
        const expandFilterTrigger = event.target.closest('.js-extend-filters');
        const expandProductsTrigger = event.target.closest('.js-extend-products');
        const goToProductTrigger = event.target.closest('.js-go-to-product');

        if ( expandFilterTrigger ) fExtendFilter(expandFilterTrigger);
        if ( expandProductsTrigger ) fExtendProducts(expandProductsTrigger, categoryId, categoryList);
        if ( goToProductTrigger ) return fGoToProduct(event);
    });

    const sortOrderDropdown = document.querySelector('#sortOrder');

    if ( sortOrderDropdown ) {
        sortOrderDropdown.addEventListener('change', function (event) {
            const sortTrigger = event.target.closest('select');

            if (sortTrigger) fSort(sortTrigger);
        });
    }

    fScroll();
}

export function fInitialiseVariables(min, max) {
    minPoints = min;
    maxPoints = max;
}

export function fSliderChanged(min = undefined, max = undefined) {
    if ( min !== undefined ){
        minPoints = min;
    }

    if ( max !== undefined ) {
        maxPoints = max;
    }

    fFilterProducts(null, categoryId, categoryList);
}

function fSort(trigger) {
    const sortArr = trigger.options[ trigger.selectedIndex ].value.split('-');
    sortOrder = sortArr[0];
    sortDir = sortArr[1];

    fFilterProducts(null, categoryId, categoryList);
}

function fShowLoader() {
    const loader = document.querySelector('#filter-loader');
    loader.classList.add('is-active');
}

function fHideLoader() {
    const loader = document.querySelector('#filter-loader');
    loader.classList.remove('is-active');
}

function fGoToProduct() {
    const categoryId = new URLSearchParams(window.location.href).get('path');
    const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;

    fSetCookie('scrollTo', `${categoryId}/${scrollPos}`, 2);

    return true;
}

function fScroll() {
    if ( !fCookieExists('scrollTo') ) return;

    const cookie = fGetCookie('scrollTo').split('/');
    const cookieCat = cookie[0];
    const currCat = new URLSearchParams(window.location.href).get('path');
    const productId = new URLSearchParams(window.location.href).get('product_id');

    if ( cookieCat !== currCat && typeof productId !== 'undefined' ) return fClearCookie('scrollTo');

    // Set scroll position
    scrollPos = parseInt(cookie[1], 10);

    // Extend our products after we set scrollPos. Make sure it is defined when the xhr completes
    const extend = document.querySelector('.js-extend-products');
    if ( extend ) extend.click();

    fClearCookie('scrollTo');
}

function fSetCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/; SameSite=Strict; Secure`;
}

function fGetCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

function fCookieExists(cname) {
    return fGetCookie(cname) !== '';
}

function fClearCookie(cname) {
    if ( !fCookieExists(cname) ) return;

    document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
}