let didLoad = false;

export default function fInitCharity() {
    document.addEventListener('click', event => {
        const triggerAdd = event.target.closest('.js-charity-add');
        const triggerLoad = event.target.closest('.js-load-donations');

        if (triggerAdd) fAdd(triggerAdd);
        if (triggerLoad) fLoadDonations();
    });
}

function fLoadDonations() {
    if ( didLoad ) return;
    didLoad = true;

    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
        document.querySelector('.donate__loader').style.display = 'none';
        if (xhr.status >= 200 && xhr.status < 300) {
            document.querySelector('.donations__holder').innerHTML = xhr.response;
        }
    };

    xhr.open('GET', '/index.php?route=product/product/charities');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.send();
}

function fAdd(trigger) {
    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
            window.location.href = trigger.getAttribute('data-redirect');
        }
    };
    xhr.open(trigger.method, `${trigger.action}&product_id=${trigger.querySelector('input[name=product_id]').value}`);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.send();
}