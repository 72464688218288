export default function fInitCounter() {
    const form = document.querySelector('.js-cart-add');
    const counter = form.querySelector('.counter');
    const total = form.querySelector('.counter__total');
    const points = form.querySelector('input[name=points]');

    const pointsMax = counter.getAttribute('data-max-points');
    const pointsMin = counter.getAttribute('data-min-points');
    const pointsVal = 100;
    const pointsDelta = counter.getAttribute('data-step-size');

    const isEuroShop = Boolean(total.querySelector('.counter__total__sub__hidden'));

    document.addEventListener('click', event => {
       const plusTrigger = event.target.closest('.counter__plus');
       const minTrigger = event.target.closest('.counter__minus');
       const pointsValue = isEuroShop ? points.value * 100 : points.value;
       if (plusTrigger){
           let newPoints = fPlus(pointsValue, pointsDelta, pointsMin, pointsMax);
           fChangeValue(points, newPoints, isEuroShop);
           fShowValue(total, newPoints, pointsVal, isEuroShop);
       }

       if (minTrigger) {
           let newPoints = fMin(pointsValue, pointsDelta, pointsMin, pointsMax);
           fChangeValue(points, newPoints, isEuroShop);
           fShowValue(total, newPoints, pointsVal, isEuroShop);
       }
    });
}

function fPlus(currPoints, pointsDelta, minPoints, maxPoints) {
    return fChange(currPoints, pointsDelta, minPoints, maxPoints);
}

function fMin(currPoints, pointsDelta, minPoints, maxPoints) {
    return fChange(currPoints, -pointsDelta, minPoints, maxPoints);
}

function fChange(currPoints, pointsDelta, minPoints, maxPoints) {
    const newPoints = parseInt(currPoints, 10) + parseInt(pointsDelta, 10);

    if ( newPoints > maxPoints) { return maxPoints; }

    if ( newPoints < minPoints ) { return minPoints; }

    return Math.min(maxPoints, Math.max(minPoints, newPoints));
}

function fChangeValue(field, points, isEuroShop) {
    field.value = isEuroShop ? points / 100 : points;
}

function fShowValue(totalField, newPoints, pointVal, isEuroShop) {
    let value = (parseInt(newPoints, 10) / parseInt(pointVal, 10)).toString(10);
    value = value.split('.');
    let cents = parseInt(value[1], 10) * 10;
    cents = cents < 1 || isNaN(cents) || cents === 'undefined' ? '-' : cents;
    totalField.querySelector('.counter__total__main span.euros').innerHTML = value[0];
    totalField.querySelector('.counter__total__main span.cents').innerHTML = cents;
    if ( !isEuroShop ) { // If we should consider points
        totalField.querySelector('.counter__total__sub span').innerHTML = new Intl.NumberFormat('nl-nl').format(newPoints);
    } else {
        totalField.querySelector('.counter__total__sub__hidden span').innerHTML = new Intl.NumberFormat('nl-nl').format(newPoints);
    }

    if ( newPoints > 0 ) { fEnableBuy(); }
}

function fEnableBuy() {
    document.querySelector('.product-hero__content__action__add-to-cart').removeAttribute('disabled');
}