export default function fInitFaq() {
    document.addEventListener('click', function (event) {
        const switchCategoryTrigger = event.target.closest('.js-faq-cat');
        const faqToggleTrigger = event.target.closest('.js-faq-toggle');

        if ( switchCategoryTrigger ) fSwitchCategory(switchCategoryTrigger);
        if ( faqToggleTrigger ) fFaqToggle(faqToggleTrigger);
    })
}

function fFaqToggle(trigger) {
    const openAnswer = document.querySelector('.faq__card__questions.is-active .faq__answer.is-active');
    const openTrigger = document.querySelector('.js-faq-toggle.open');
    const closing = trigger.classList.contains('open');

    if (openAnswer) openAnswer.classList.remove('is-active');
    if (openTrigger) openTrigger.classList.remove('open');

    if ( !closing ) {
        trigger.classList.add('open');
        trigger.parentNode.querySelector('.faq__answer').classList.add('is-active');
    }
}

function fSwitchCategory(trigger) {
    const openCard = trigger.getAttribute('data-cat');
    const newOpenCard = document.querySelector(`.faq__card__questions[data-cat="${openCard}"]`);

    fHideAll();
    fDeactivateAll();
    newOpenCard.classList.add('is-active');
    trigger.classList.add('is-active');
}

function fHideAll() {
    const cards = document.querySelectorAll('.faq__card__questions');
    for ( const card of cards ) {
        card.classList.remove('is-active');
    }
}

function fDeactivateAll() {
    const triggers = document.querySelectorAll('.js-faq-cat');

    for ( const trigger of triggers ) {
        trigger.classList.remove('is-active');
    }
}