import cart from './cart';

let points = 0;
export default function fInitShopInShop() {
    const sis = document.querySelector('.js-sis');

    points = parseInt(sis.dataset.points, 10);

    document.addEventListener('click', function(event) {
        const addToCartEventTrigger = event.target.closest('#button-cart:not(disabled)');
        const expandProductsTrigger = event.target.closest('.js-extend-sis');
        const productRadioTrigger = event.target.closest('.js-radio-product input[type=radio]');

        if ( addToCartEventTrigger ) fAddToCart(addToCartEventTrigger);
        if ( expandProductsTrigger ) fExtendProducts();
        if ( productRadioTrigger ) scrollToBottom();
    });

    const checkboxes = document.querySelectorAll('.js-checkbox-product input[type=checkbox]');

    for ( const checkbox of checkboxes ) {
        checkbox.addEventListener('change', fHandleCheckboxClick)
    }
}

function fHandleCheckboxClick(trigger) {
    const cartGroup = document.querySelector('.products__cards .order');

    if ( trigger.target.checked ){
        points -= parseInt(trigger.target.dataset.points, 10);
    } else {
        points += parseInt(trigger.target.dataset.points, 10);
    }

    const checkboxes = document.querySelectorAll('input[name*=option][type=checkbox]');

    for ( let checkbox of checkboxes ) {
        if ( parseInt(checkbox.dataset.points, 10) > points ) {
            if ( ! checkbox.checked) {
                checkbox.setAttribute('disabled', 'disabled');
            }
        } else {
            checkbox.removeAttribute('disabled');
        }
    }

    fEditPointsRemaining(points);

    if ( points === 0 ) {
        cartGroup.classList.remove('is-hidden');
        scrollToBottom();
    } else {
        cartGroup.classList.add('is-hidden');
    }
}

function fEditPointsRemaining(points) {
    const pointsLeft = document.querySelector('#pointsLeft');

    if ( points === 0 ) {
        pointsLeft.classList.add('hidden');
    } else {
        const pointsLeftSpan = pointsLeft.querySelector('span');

        if ( pointsLeft.classList.contains('hidden') ) {
            pointsLeft.classList.remove('hidden');
        }
        if ( pointsLeftSpan ) {
            pointsLeftSpan.innerHTML = points;
        }
    }

}

function fExtendProducts() {
    const products = document.querySelectorAll('.product-hidden');

    for (const product of products) {
        product.classList.remove('product-hidden');
    }

    document.querySelector('.js-extend-sis').remove();
}

function fAddToCart() {
    const xhr = new XMLHttpRequest();
    const productId = document.querySelector('.order input[name=product_id]').value;
    const quantity = document.querySelector('.order input[name=quantity]').value;
    const optionInputs = document.querySelectorAll('input[name*=option]');
    let options = [];

    for ( let optionInput of optionInputs) {
        if ( optionInput.checked ) {
            options.push(`${optionInput.name}=${optionInput.value}`);
        }
    }

    const divError = document.querySelector('#diverror');

    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
            const json = JSON.parse(xhr.response);

            if (!json['error'] && json['redirect']) {
                window.location.href = json['redirect'];
            }

            if (json['error']) {
                if (json['error']['option']) {
                    for (let i in json['error']['option']) {
                        divError.innerHTML = '<span class="error">' + json['error']['option'][i] + '</span>';
                        scrollToTop();
                    }
                }
            }

            if (json['success']) {
                $('#notification').html('<div class="success" style="display: none;">' + json['success'] + '<img src="catalog/view/theme/default/image/close.png" alt="" class="close" /></div>');
                $('.success').fadeIn('slow');
                $('html, body').animate({ scrollTop: 0 }, 'slow');

                $('#cart-total').html(json['total']);
            }
        }
    };

    let body = `product_id=${productId}&quantity=${quantity}`;


    if ( options.length > 0 ) { body += `&${options.join('&')}`; }

    xhr.open('POST', 'index.php?route=checkout/cart/add');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.send(body);
}

const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - (c / 8));
    }
};

const scrollToBottom = () => {
    // const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    // const scrollToTop = document.documentElement.scrollTop || document.body.scrollTop;
    // const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
    // const current = scrollToTop;
    // const remaining = scrollHeight - scrollToTop - clientHeight;
    //
    // if (remaining > 10) {
    //     window.requestAnimationFrame(scrollToBottom);
    //     window.scrollTo(0, current + (remaining / 8));
    // }

    document.getElementById('order').scrollIntoView(false);
};