if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector
    || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        let el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

// Helper Functions
export default function fInitToggle() {
    document.addEventListener('click', function (event) {
        const trigger = event.target.closest('[data-toggle]');        
        
        if (trigger) {
            const showId = trigger.getAttribute('data-toggle-show');
            const hideId = trigger.getAttribute('data-toggle-hide');
            
            if (!showId && !hideId) return;

            const showTargets = Array.prototype.slice.call(document.querySelectorAll(`[data-toggle-id='${showId}']`));
            const hideTargets = Array.prototype.slice.call(document.querySelectorAll(`[data-toggle-id='${hideId}']`));
                
            showTargets.forEach(target => target.classList.remove('is-hidden'));
            hideTargets.forEach(target => target.classList.add('is-hidden'));
        }
    });
}

export function fCustomReload() {
    window.location.href = window.location.href.replace('show_cart=1', '');
}