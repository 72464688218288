import fInitMobileNav from './mobile-nav';

export default function initHeader() {

    const header = document.querySelector('.header');
    const headerTop = document.querySelector('.header__top');
    const headerMain = document.querySelector('.header__main');
    const headerMobileSearch = document.querySelector('.header__mobile-search');
    const headerSpacer = document.querySelector('.header__spacer');
    const searchForm = document.querySelector('#form-search');
    let headerTopHeight = headerTop.offsetHeight;
    let dropdownActive = false;
    const dropdown = document.querySelector('.header__dropdown');

    checkHeaderFix();
    fResizeHeaderSpacer();

    document.addEventListener('click', function(e){
        const trigger = event.target.closest('.js-open-dropdown');
        if (!trigger) return;
        openDropdown(e);
    });

    document.addEventListener('click', function (event) {
        if (event.target === event.target.closest('.dropdown-backdrop')) {
            closeDropdown();
        }
    });
    document.addEventListener('keydown', function (event) {
        // Close active modal when esc key is pressed 
        if (event.keyCode === 27) {
            closeDropdown();
        }
    });

    if (searchForm) {
        searchForm.addEventListener('submit', function (e) {
            e.preventDefault();
            const search = document.querySelector('#form-search input[name=search]').value;
            window.location.href = `${searchForm.action}&search=${search}`;
        });
    }

    // Sticky header on scroll
    window.addEventListener('scroll', function () {
        checkHeaderFix();
    });

    function checkHeaderFix() {
        if (window.pageYOffset >= headerTopHeight) {
            header.classList.add('fixed-header');
        } else {
            header.classList.remove('fixed-header');
        }

        if (headerMobileSearch) {
            if (window.pageYOffset >= 100) {
                headerMobileSearch.classList.add('hidden');
            } else {
                headerMobileSearch.classList.remove('hidden');
            }
        }
    }

    function fResizeHeaderSpacer() {
        headerSpacer.style.height = `${headerTop.offsetHeight + headerMain.offsetHeight}px`;
    }

    window.onresize = function (event) {
        headerTopHeight = headerTop.offsetHeight;
        fResizeHeaderSpacer();
    };

    // Hover dropdown items state
    const dropdownItems = document.querySelectorAll('.header__dropdown__cat-list__item');

    for (let index = 0; index < dropdownItems.length; index++) {
        const element = dropdownItems[index];
        const elementLink = element.querySelector('.header__dropdown__cat-list__link');
        const contentWrapper = element.querySelector('.header__dropdown__main__wrapper');

        element.onmouseover = function (event) {
            for (let index = 0; index < dropdownItems.length; index++) {
                const item = dropdownItems[index];
                const itemLink = item.querySelector('.header__dropdown__cat-list__link');
                
                itemLink.classList.remove('hovered');
            }
            elementLink.classList.add('hovered');

            // Get content height
            // const elHeight = contentWrapper.clientHeight;
            // dropdown.style.height = elHeight + 'px';
        };

        element.onmouseout = function () {
            // elementLink.classList.remove('hovered');
        };             
    };


    // Functions
    function openDropdown(event) {
        const catList = document.querySelector('.header__dropdown');
        const catButton = document.querySelector('.header__cat-button .button');

        event.preventDefault();

        if (dropdownActive === true) {
            closeDropdown();
            return;
        }
        dropdownActive = true;

        catList.classList.add('active');
        catButton.classList.add('active');

        // Create modal backdrop
        const header = document.querySelector('.header');

        /// Main backdrop
        const oDropdownBackdrop = header.appendChild(document.createElement('div'));
        oDropdownBackdrop.classList.add('dropdown-backdrop');

        // Header backdrop
        const headerTop = document.querySelector('.header__top');
        const headerMain = document.querySelector('.header__main');

        const oDropdownHeaderMainBackdrop = headerMain.appendChild(document.createElement('div'));
        oDropdownHeaderMainBackdrop.classList.add('dropdown-header-backdrop');

        const oDropdownHeaderTopBackdrop = headerTop.appendChild(document.createElement('div'));
        oDropdownHeaderTopBackdrop.classList.add('dropdown-header-backdrop');

        const timeline = new TimelineMax();

        timeline.set(oDropdownBackdrop, {
            display: 'block'
        })
        .set('.dropdown-header-backdrop', {
            display: 'block'
        }).to(oDropdownBackdrop, 0.5, {
            autoAlpha: 1
        }, 0)
        .to('.dropdown-header-backdrop', 0.5, {
            autoAlpha: 1
        }, 0);
    }

    function closeDropdown() {
        const catButton = document.querySelector('.header__cat-button .button');
        const catList = document.querySelector('.header__dropdown');
        const oDropdownBackdrop = document.querySelector('.dropdown-backdrop');
        const oDropdownHeaderBackdrop = document.querySelectorAll('.dropdown-header-backdrop');

        if (dropdownActive === false) {
            return;
        }
        dropdownActive = false;

        catList.classList.remove('active');
        catButton.classList.remove('active');

        const timeline = new TimelineMax();

        timeline.to(oDropdownBackdrop, 0.5, {
            autoAlpha: 0
        }, 0)
        .to('.dropdown-header-backdrop', 0.5, {
            autoAlpha: 0
        }, 0)
        .set(oDropdownBackdrop, {
            display: 'none'
        })
        .set('.dropdown-header-backdrop', {
            display: 'none',
            onComplete: fDropdownAnimationCallback
        });

        function fDropdownAnimationCallback() {
            oDropdownBackdrop.parentNode.removeChild(oDropdownBackdrop);
            for (let index = 0; index < oDropdownHeaderBackdrop.length; index++) {
                oDropdownHeaderBackdrop[index].parentNode.removeChild(oDropdownHeaderBackdrop[index]);
            }
        }
    }

    fInitMobileNav();
}