export default function fInitMobileFilters() {
    let isAnimating = false;

    document.addEventListener('click', function(event){
        const trigger = event.target.closest('.js-open-mobile-filters');
        if (!trigger) return;
        openMobileFilters();
    });

    document.addEventListener('click', function(event){
        const trigger = event.target.closest('.js-close-mobile-filters');
        if (!trigger) return;
        closeMobileFilters();
    });

   function openMobileFilters() {
        if (isAnimating) return;
        isAnimating = true;

        const oTimeline = new TimelineMax();
        const mobileNav = document.querySelector('.mobile-filter');

        mobileNav.classList.add('is-active');

        // Animate
        oTimeline.timeScale(2.5);
        oTimeline
        .set(mobileNav, {
            display: 'block'
        }).to(mobileNav, 1, {
            y: 0,
            x: 0,
            autoAlpha: 1,
            ease: 'Power2.easeOut',
            onComplete: animationCallback
        });
    };

    function closeMobileFilters() {
        if (isAnimating) return;
        isAnimating = true;

        const oTimeline = new TimelineMax();
        const mobileNav = document.querySelector('.mobile-filter');

        function animationCallback() {
            isAnimating = false;
        }

        mobileNav.classList.remove('is-active');

        // Animate out
        oTimeline.timeScale(2);
        oTimeline
        .to(mobileNav, 1, {
            autoAlpha: 0,
            y: 0,
            x: 0,
            ease: 'Power2.easeOut'
        })
        .set(mobileNav, {
            display: 'none',
            onComplete: animationCallback
        });
    };

    function animationCallback() {
        isAnimating = false;
    }
}