export default function fInitLanguageSwitch() {
    document.addEventListener('click', event => {
        const langButton = event.target.closest('.js-lang-button');

        if ( langButton ) fSwitchLang(langButton);
    });
}

function fSwitchLang(trigger) {
    const form = trigger.closest('form');
    const language = trigger.getAttribute('data-lang');
    const languageInput = form.querySelector('input[name=language_code]');
    languageInput.value = language;

    form.submit();
}