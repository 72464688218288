import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import {fInitialiseVariables, fSliderChanged} from './cat-overview';

export default function fInitRangeSlider() {
    const handlesSliders = document.querySelectorAll('.range-slider');

    for (let index = 0; index < handlesSliders.length; index++) {
        const element = handlesSliders[index];
        const sliderElement = element.querySelector('.js-slider');
        const sliderInputMax = element.querySelector('.range-slider__input--max');
        const sliderInputMin = element.querySelector('.range-slider__input--min');

        const min = parseInt(sliderElement.getAttribute('data-min'), 10);
        const max = parseInt(sliderElement.getAttribute('data-max'), 10);
        const currentMin = fGetCookie('currMin') !== '' ? fGetCookie('currMin') : parseInt(sliderElement.getAttribute('data-current-min'), 10);
        const currentMax = fGetCookie('currMax') !== '' ? fGetCookie('currMax') : parseInt(sliderElement.getAttribute('data-current-max'), 10);

        noUiSlider.create(sliderElement, {
            start: [currentMin, currentMax],
            connect: true,
            step: 100,
            format: wNumb({
                decimals: 0
            }),
            range: {
                'min': [min],
                'max': [max]
            }
        }, true);

        const urlVars = getUrlVars();

        sliderElement.noUiSlider.set([fGetCurrentMin(sliderElement, urlVars), fGetCurrentMax(sliderElement, urlVars)]);

        sliderElement.noUiSlider.on('update', function (values, handle) {
            const value = values[handle];
            if (handle === 0) {
                sliderInputMin.value = value;
                fSetCookie('currMin', value, 50);
            }
            if (handle === 1) {
                sliderInputMax.value = value;
                fSetCookie('currMax', value, 50);
            }
        });

        // Change slider when input field changes
        sliderInputMax.addEventListener('change', function () {
            sliderElement.noUiSlider.setHandle(1, this.value);
            fSliderChanged(undefined, this.value);
        });
        sliderInputMin.addEventListener('change', function () {
            sliderElement.noUiSlider.setHandle(0, this.value);
            fSliderChanged(this.value, undefined);
        });

        sliderElement.noUiSlider.on('end', function (test) {
            fSliderChanged(parseInt(test[0], 10), parseInt(test[1], 10));
        });

        const current = sliderElement.noUiSlider.get();
        fInitialiseVariables(current[0], current[1]);
    }
}

function fGetCurrentMin(sliderElement, urlVars) {
    if ( typeof urlVars['range_from'] !== 'undefined' ) {
        return urlVars['range_from'];
    }

    if ( fGetCookie('currMin') !== '' ) {
        return fGetCookie('currMin')
    }

    return parseInt(sliderElement.getAttribute('data-current-min'), 10);
}

function fGetCurrentMax(sliderElement, urlVars) {
    if ( typeof urlVars['range_to'] !== 'undefined' ) {
        return urlVars['range_to'];
    }

    if ( fGetCookie('currMax') !== '' ) {
        return fGetCookie('currMax')
    }

    return parseInt(sliderElement.getAttribute('data-current-max'), 10);
}

function getUrlVars() {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
        vars[key] = value;
    });
    return vars;
}

function fSetCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/; SameSite=Strict; Secure`;
}

function fGetCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

function fCookieExists(cname) {
    return fGetCookie(cname) !== '';
}

function fClearCookie(cname) {
    if ( !fCookieExists(cname) ) return;

    document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
}