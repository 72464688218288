import alert from './alert';
import {fCustomReload} from './helpers';

function fGetProductData (trigger) {
    return {
        id: trigger.getAttribute('data-wishlist-productId'),
        name: trigger.getAttribute('data-wishlist-productName')
    }
}

function fAdd (trigger) {
    const productData = fGetProductData(trigger);

    if (!productData) return;

    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
            trigger.classList.add('is-active');
            trigger.classList.remove('js-wishlist-add');
            trigger.classList.add('js-wishlist-remove');

            alert(`${productData.name} ${translations.wishlistAdded}`);
        }
    };
    xhr.open('POST', '/index.php?route=account/wishlist/add');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.send(`product_id=${productData.id}`);
}

function fRemove (trigger) {
    const productData = fGetProductData(trigger);

    if (!productData) return;

    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
            if ( trigger.hasAttribute( 'data-refresh') ) return fCustomReload();

            trigger.classList.remove('is-active');
            trigger.classList.remove('js-wishlist-remove');
            trigger.classList.add('js-wishlist-add');

            alert(`${productData.name} ${translations.wishlistRemoved}`);
        }
    };
    xhr.open('POST', '/index.php?route=account/wishlist/remove');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.send(`product_id=${productData.id}`);
}

export default function fInitWishlist () {
    document.addEventListener('click', event => {
        const addTrigger = event.target.closest('.js-wishlist-add');
        const removeTrigger = event.target.closest('.js-wishlist-remove');

        if (addTrigger) fAdd(addTrigger);
        if (removeTrigger) fRemove(removeTrigger);
    });
}