import validate from './vendor/validate';

export default function fFormValidation() {
    const aForms = document.querySelectorAll('.js-form-validate');
    const aSubmitFunctions = {};

    // Helper function to get recaptcha widget id
    function GetRecaptchaID(sContainerID) {
        let nResult = -1;
        const aRecaptchas = document.querySelectorAll('.js-recaptcha');
    
        for (let i = 0; i < aRecaptchas.length; i += 1) {
            if (aRecaptchas[i].id === sContainerID) {
                nResult = i;
            }
        }
    
        return nResult;
    }

    // Initialize form validation
    validate.init({
        selector: '.js-form-validate',
        fieldClass: 'has-error',
        errorClass: 'form__group__error',
        disableSubmit: true,
        onSubmit: function(oForm) {
            const oRecaptchaContainer = oForm.querySelector('.js-recaptcha');
            
            if (oRecaptchaContainer) {
                const nRecaptchaID = GetRecaptchaID(oRecaptchaContainer.id);
                
                grecaptcha.reset(nRecaptchaID);
                grecaptcha.execute(nRecaptchaID);
            } else {
                oForm.submit();
            }
        },
        onLoad: function() {
            for (let i = 0; i < aForms.length; i += 1) {
                const oRecaptchaContainer = aForms[i].querySelector('.js-recaptcha');
                const sRecaptchaSitekey = oRecaptchaContainer.getAttribute('data-recaptcha-key');
                const oSubmitButton = aForms[i].querySelector('button');

                aSubmitFunctions[i] = function() {
                    aForms[i].submit();
                }

                if (oRecaptchaContainer && grecaptcha) {
                    grecaptcha.render(oRecaptchaContainer, {
                        'sitekey': sRecaptchaSitekey,
                        'size': 'invisible',
                        'callback': aSubmitFunctions[i],
                    });
                }

                if (oSubmitButton) {
                    oSubmitButton.removeAttribute('disabled');
                }
            }
        }
    });
}
