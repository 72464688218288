export default function fBodyScrollEnabled(enabled) {
    if (enabled === false) {
        document.body.addEventListener('touchmove', preventDefault, { passive: false });
        document.querySelectorAll('.js-inner-scroll').forEach(function (el) {
            el.addEventListener('touchmove', stopPropagation, { passive: false});
        })
    } else {
        document.body.removeEventListener('touchmove', preventDefault);
        document.querySelectorAll('.js-inner-scroll').forEach(function (el) {
            el.removeEventListener('touchmove', stopPropagation);
        })
    }
}

function stopPropagation(e) {
    e.stopPropagation();
}

function preventDefault(e) {
    const trigger = e.target.closest('body');

    if ( !trigger ) return;
    e.preventDefault();
}