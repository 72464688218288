import fBodyScrollEnabled from './disable-scroll';

export default function fInitModal() {
    let bModalActive = false;
    let bAnimating = false;
    let oCurrentModal;
    
    function fOpenModal (oModal, oModalBackdrop) {
        bAnimating = false;
        const oTimeline = new TimelineMax();
        
        bAnimating = true;
        
        function fAnimationCallback() {
            bAnimating = false;
        }

        // Lock body
        document.body.style.overflow = 'hidden';

        // Animate
        oTimeline.timeScale(2);

        if (oModal.classList.toString().indexOf('modal--align-right') > -1) {
            oTimeline
            .set(oModalBackdrop, {
                display: 'block'
            }).to(oModal, 1, {
                x: 0,
                autoAlpha: 1
            }).to(oModalBackdrop, 0.5, {
                autoAlpha: 1,
                onComplete: fAnimationCallback
            }, 0);
        } else {
            oTimeline
            .set(oModalBackdrop, {
                display: 'block'
            }).to(oModal, 1, {
                y: -30,
                autoAlpha: 1
            }).to(oModalBackdrop, 0.5, {
                autoAlpha: 1,
                onComplete: fAnimationCallback
            }, 0);
        }

        fBodyScrollEnabled(false);
    }

    function fCloseModal () {
        const oTimeline = new TimelineMax();
        const oModal = oCurrentModal;
        const oModalBackdrop = document.querySelector('.modal-backdrop');
        if (!oModal || !oModalBackdrop) return;
        
        bModalActive = false;
        bAnimating = true;
        
        function fAnimationCallback() {
            bAnimating = false;
            document.body.removeChild(oModalBackdrop);
        }

        // Remove scroll lock
        document.body.style.overflow = 'auto';
        
        // Animate out
        oTimeline.timeScale(2);
        
        if (oModal.classList.toString().indexOf('modal--align-right') > -1) {
            oTimeline
            .to(oModal, 0.5, {
                autoAlpha: 0,
                x: 30
            }).to(oModalBackdrop, 0.5, {
                autoAlpha: 0
            }, 0).set(oModalBackdrop, {
                display: 'none',
                onComplete: fAnimationCallback
            });
        } else {
            oTimeline
            .to(oModal, 0.5, {
                autoAlpha: 0,
                y: 0
            }).to(oModalBackdrop, 0.5, {
                autoAlpha: 0
            }, 0).set(oModalBackdrop, {
                display: 'none',
                onComplete: fAnimationCallback
            });
        }

        fBodyScrollEnabled(true);
    }
    
    function fPopulate (oContent, oModal) {
        let oData = JSON.parse(oContent);
            
        // Loop over each key/value pair in json data
        Object.entries(oData).forEach(([key, value]) => {
            // Get corresponding field in modal
            let aFields = oModal.querySelectorAll(`.js-modal-${key}`);

            // Fill field with data value
            for (let a = 0; a < aFields.length; a += 1) {
                if (aFields[a].tagName === 'INPUT') {
                    aFields[a].value = value;
                } else {
                    aFields[a].innerHTML = value;
                }
            }
        });
    }
    
    function fGetModal (oTrigger) {        
        let oModal = document.getElementById(oTrigger.getAttribute('data-modal-target'));
        
        if (!oModal) {
            return;
        }

        // Create modal backdrop
        let oModalBackdrop = document.body.appendChild(document.createElement('div'));
        oModalBackdrop.classList.add('modal-backdrop');
        oModalBackdrop.classList.add('js-modal-close');
        
        // If trigger contains JSON content, populate first
        let oModalContent = oTrigger.getAttribute('data-modal-content');
        
        if (oModalContent) {
            fPopulate(oModalContent, oModal);
        }

        // Show modal and background
        fOpenModal(oModal, oModalBackdrop);
        
        // Set global variables
        bModalActive = true;
        oCurrentModal = oModal;
    }
    
    // Click handler
    document.addEventListener('click', function (event) {
        if (bAnimating) return;
        
        if (event.target.closest('.js-modal-open')) {
            
            if (bModalActive) {
                fCloseModal();
            }
            
            fGetModal(event.target.closest('.js-modal-open'));
        } else if (event.target.closest('.js-modal-close')) {
            fCloseModal();
        }
    });
    
    // Key handler
    document.addEventListener('keydown', function (event) {
        // Close active modal when esc key is pressed 
        if (event.keyCode === 27 && bModalActive) {
            fCloseModal();
        }
    });

    const autoOpenModal = document.querySelector('.js-modal-auto-open');
    if ( autoOpenModal ) { autoOpenModal.click(); }
}