export default function fInitCustomDropdowns() {
    const aCustomDropdown = document.querySelector('.js-dropdown');
    const aCustomDropdownList = document.querySelector('.dropdown__list');
    const aCustomDropdownTrigger = aCustomDropdown.querySelector('.dropdown__trigger');

    // aCustomDropdownTrigger.addEventListener('click', function () {
    //     if (aCustomDropdownList.classList.contains('is-open')) {
    //         fCloseDropdown();
    //     } else {
    //         fOpenDropdown();
    //     }
    // });

    function fOpenDropdown() {
        aCustomDropdown.classList.add('is-open');
        aCustomDropdownList.classList.add('is-open');
    };

    function fCloseDropdown() {
        aCustomDropdown.classList.remove('is-open');
        aCustomDropdownList.classList.remove('is-open');
    };

    document.addEventListener('click', function (event) {
        if (event.target.closest('.js-dropdown')) {
            if (event.target.closest('.js-dropdown.is-open .dropdown__trigger')) {
                fCloseDropdown();
            } else {
                fOpenDropdown();
            }
        } else if (event.target.closest('.dropdown__trigger')) {
            fCloseDropdown();                    
        } else if (aCustomDropdown.classList.contains('is-open')) {
            fCloseDropdown();
        }
    }, false);
}